@keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .logo {
    display: inline-block;
    transition: transform 1s;
  }
  
  .logo:hover {
    animation: spin 1s linear;
  }
  